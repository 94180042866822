import { useEffect, useState, useRef } from "react";
import L, { icon } from "leaflet";
import { Marker, Tooltip } from "react-leaflet";
import axios from "axios";
import classes from "./ArmyMarker.module.scss";

const ArmyMarker = ({
  position,
  zoomLevel,
  armies,
  selectedArmy,
  showTooltip,
  nodes,
  ...props
}) => {
  const token = window.localStorage.getItem("loggedBLUser");
  const [iconSize, setIconSize] = useState([20, 20]);
  const [markerKey, setMarkerKey] = useState(0);
  const isDraggingRef = useRef(false);
  const isDraggable = selectedArmy && armies.some(a => a.id === selectedArmy.id);

  useEffect(() => {
    if (zoomLevel === 5) setIconSize([24, 24]);
    else if (zoomLevel === 6) setIconSize([44, 44]);
    else if (zoomLevel === 7) setIconSize([88, 88]);
    setMarkerKey(prevKey => prevKey + 1);
  }, [zoomLevel]);

  let enemy = 0, friend = 0, scout = 0;
  armies.forEach(army => {
    if (army.status === "enemy") enemy++;
    if (army.status === "friend") friend++;
    if (army.status === "scout") scout++;
  });

  let flag = "Images/flags/neutral.png";
  if (enemy > 0 && friend === 0) flag = "Images/flags/enemy.png";
  if (enemy === 0 && friend > 0) flag = "Images/flags/friend.png";
  if (enemy > 0 && friend > 0) flag = "Images/flags/battle.png";

  const BannerIcon = new icon({
    iconUrl: flag,
    iconSize: scout === armies.length ? [0, 0] : iconSize,
    iconAnchor: [iconSize[0] / 2, iconSize[1]]
  });

  const onDragStart = () => {
    isDraggingRef.current = true;
  };

  const onDragEnd = async (e) => {
    isDraggingRef.current = false;
    const dragPosition = e.target.getLatLng();

    let closestNode = null;
    let closestNodePosition = null;
    let minDistance = Infinity;
    nodes.forEach(node => {
      // node.position is [lat, lng], so turn that into a Leaflet LatLng
      const nodeLatLng = L.latLng(node.position[0], node.position[1]);
      const distance = dragPosition.distanceTo(nodeLatLng); // distance in meters
      if (distance < minDistance) {
        minDistance = distance;
        closestNode = node;
        closestNodePosition = nodeLatLng;
      }
    });

    if (minDistance < 29000 && selectedArmy.node !== closestNode.id) {
      e.target.setLatLng(closestNodePosition);
      const { node: _oldNode, id, ...armyFields } = selectedArmy;
      await axios.put(
        `https://battle-legion-backend.onrender.com/api/armies/${id}`,
        { ...armyFields, node: closestNode.id },
        { headers: { Authorization: `Bearer ${token}` } }
      );
      window.location.reload();
    } else {
      e.target.setLatLng(position); // // Put back to original position
    }
  };

  return (
    <Marker
      key={markerKey}
      {...props}
      position={position}
      className={classes.flag}
      icon={BannerIcon}
      draggable={isDraggable}
      zIndexOffset={9999}
      eventHandlers={{
        dragstart: onDragStart,
        dragend: onDragEnd,
        ...(props.eventHandlers || {})
      }}
    >
      {isDraggable && showTooltip && (
        <Tooltip
          className="custom-tooltip"
          permanent
          direction="top"
          offset={[0, -iconSize[0]]}
        >
          {selectedArmy?.name}
        </Tooltip>
      )}
    </Marker>
  );
};

export default ArmyMarker;
