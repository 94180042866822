import { useState, useEffect, useRef } from "react";
import { DomEvent } from "leaflet";
import classes from "./NodeBox.module.scss";
import ArmyForm from "./Forms/ArmyForm";
import { nodes } from "../../Data/nodes";

export const NodeBox = ({ node, armies, onClose, disabled, ...props }) => {
  const [activeArmy, setActiveArmy] = useState();
  const [creatingArmy, setCreatingArmy] = useState(false);

  const containerRef = useRef(null);
  useEffect(() => {
    if (containerRef.current) {
      DomEvent.disableScrollPropagation(containerRef.current);
    }
  }, [containerRef]);

  return (
    <>
      {creatingArmy && (
        <ArmyForm
          nodes={nodes}
          nodeNumber={node.id}
          onClose={() => {
            setCreatingArmy(null);
            onClose();
          }}
          disabled={disabled}
        />
      )}

      {activeArmy && (
        <ArmyForm
          key={`army-form-${activeArmy.id}`} // Use a unique key for each activeArmy
          nodes={nodes}
          onClose={() => {
            setActiveArmy(null);
            onClose();
          }}
          army={activeArmy}
          disabled={disabled}
        />
      )}
      <div className={classes.nodeBox} ref={containerRef}>
        <ul>
          <li>
            <span>
              <b>Node:</b> <span className={classes.prop}>{node.id}</span>
            </span>
            <span className={classes.prop}>
              {node.name} {node.walls}
            </span>
          </li>
          <li>
            <div onClick={() => setCreatingArmy(true)} className={classes.btn}>
              Add new army here
            </div>
          </li>
          <li>
            <b>Armies here:</b>
            <ul className={classes.armies}>
              {armies
                .filter((army) => army.node === node.id)
                .sort((a, b) => (a.status === 'scout' ? 1 : -1)) // Put armies with status 'scout' at the bottom
                .map((army) => (
                  <li
                    key={`army-${army.id}`}
                    onClick={() => {
                      setActiveArmy(army);
                    }}
                  >
                    {army.name}
                  </li>
                ))}
            </ul>
          </li>
        </ul>
      </div>
    </>
  );
};

export default NodeBox;
