import { useState, useEffect, useRef } from "react";
import { DomEvent } from "leaflet";
import axios from "axios";
import CloseButton from "../../Buttons/CloseButton";
import classes from "./ArmyForm.module.scss";

const ArmyForm = ({ onClose, army, nodeNumber, disabled }) => {
  const [name, setName] = useState(army ? army.name : "");
  const [general, setGeneral] = useState(army ? army.general : "");
  const [soldiers, setSoldiers] = useState(
    army ? army.soldiers.join("\n") : ""
  );
  const [walls, setWalls] = useState(army ? army.walls : false);
  const [recruitment, setRecruitment] = useState(army ? army.recruitment : false);
  const [faction, setFaction] = useState(army ? army.faction : "o.n.e");
  const [country, setCountry] = useState(army ? army.country : "none");
  const [status, setStatus] = useState(army ? army.status : "enemy");
  const [node, setNode] = useState(army ? army.node : nodeNumber || 1);
  const [unsure, setUnsure] = useState(army ? army.unsure : false);
  const [comment, setComment] = useState(army ? army.comment : "");
  const token = window.localStorage.getItem("loggedBLUser");
  let updatedDate = army ? new Date(army.updated_date).toLocaleString() : null;

  const enemyArmies = ["o.n.e", "serbia", "greece"];

  const polandArmies = [
    "poland-welikoplskie",
    "poland-mazowieckie",
    "poland-krakowskie",
    'cehz',
    "wien",
    "estergom",
    "aegis",
    "o.n.e"
  ];
  const [isPolandArmy, setIsPolandArmy] = useState(polandArmies.includes(faction));

  const userFaction = window.localStorage.getItem("faction");

  const containerRef = useRef(null);
  useEffect(() => {
    if (containerRef.current) {
      DomEvent.disableScrollPropagation(containerRef.current);
    }
  }, [containerRef]);

  const reload = () => {
    window.location.reload(false);
  };

  function handleAuthError(e) {
    if (e.response?.status === 401) {
      window.localStorage.removeItem("loggedBLUser");
      window.location.reload();
    } else {
      throw e;
    }
  }

  const handleSubmit = async (event) => {
    const soldiersArray = soldiers
      .split("\n")
      .map(function (soldier) {
        return soldier.trim();
      })
      .filter(function (soldier) {
        return soldier !== "";
      });

    const headers = { Authorization: `Bearer ${token}` };
    try {
      if (army) {
        await axios.put(`https://battle-legion-backend.onrender.com/api/armies/${army.id}`,
          {
            name,
            general,
            soldiers: soldiersArray,
            faction,
            country,
            status,
            walls,
            recruitment,
            node,
            unsure,
            comment,
          },
          { headers }
        );
      } else {
        await axios.post("https://battle-legion-backend.onrender.com/api/armies",
          {
            name,
            general,
            soldiers: soldiersArray,
            faction,
            country,
            status,
            node,
            unsure,
            comment,
          },
          { headers }
        );
      }
      reload();
    } catch (e) {
      handleAuthError(e);
    }
  };

  const handleDelete = () => {
    axios
      .delete(
        `https://battle-legion-backend.onrender.com/api/armies/${army.id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(() => {
        reload();
      })
      .catch((e) => {
        handleAuthError(e);
      });
  };

  return (
    <div className={classes.form} ref={containerRef}>
      <CloseButton onClose={onClose} />

      <h5>{army ? "Edit Army" : "Add Army"}</h5>
      <form onSubmit={handleSubmit}>
        <div className={classes.group}>
          <label>Name*:</label>
          <input
            type="text"
            placeholder="Name"
            defaultValue={name}
            onChange={(e) => setName(e.target.value)}
            disabled={disabled}
          />
        </div>
        <div className={classes.group}>
          <label>General*:</label>
          <input
            type="text"
            placeholder="General"
            defaultValue={general}
            onChange={(e) => setGeneral(e.target.value)}
            disabled={disabled}
          />
        </div>
        <div className={classes.group}>
          <label>Soldiers:</label>
          <textarea
            type="textarea"
            placeholder={"Soldier 1\nSoldier 2\nSoldier 3"}
            defaultValue={soldiers}
            onChange={(e) => setSoldiers(e.target.value)}
            disabled={disabled}
          />
        </div>
        <div>{updatedDate && <p>Last updated: {updatedDate}</p>}</div>
        <div className={classes.group}>
          <label>Country*:</label>
          <select
            defaultValue={country}
            onChange={(e) => setCountry(e.target.value)}
            disabled={disabled}
          >
            <option value="wallachia">Wallachia</option>
            <option value="bulgaria">Bulgaria</option>
            <option value="edirne">Edirne</option>
            <option value="karesi">Karesi</option>
            <option value="bursa">Bursa</option>
            <option value="greece">Greece</option>
            <option value="albania">Albania</option>
            <option value="serbia">Serbia</option>
            <option value="bosna">Bosna</option>
            <option value="venetia">Venetia</option>
            <option value="poland-welikoplskie">Poland-Welikoplskie</option>
            <option value="poland-mazowieckie">Poland-Mazowieckie</option>
            <option value="poland-krakowskie">Poland-Krakowskie</option>
            <option value="cehz">Cehz</option>
            <option value="wien">Wien</option>
            <option value="estergom">Estergom</option>
            <option value="sicilies">Sicilies</option>
            <option value="church">Church</option>
            <option value="none">None</option>
          </select>
        </div>
        <div className={classes.group}>
          <label>Faction*:</label>
          <select
            defaultValue={faction}
            onChange={(e) => setFaction(e.target.value)}
            disabled={disabled}
          >
            <option value="scout">Scout</option>
            <option value="o.n.e">O.N.E</option>
            {userFaction === "admin" && (
              <>
                <option value="bulgaria">Bulgaria</option>
                <option value="bl-1">Battle Legion</option>
                <option value="bl-2">Second Legion</option>
                <option value="bl-3">Foreign Legion</option>
              </>
            )}
            <option value="csb">Carthage Sacred Band</option>
            <option value="medici">Medici</option>
            <option value="chaos">Lords of Chaos</option>
            <option value="vidin">Baba Vida</option>
            <option value="fellowship">Fellowship</option>
            <option value="haiduks">Haiduk Batalion</option>
            <option value="tau-rohen">Tau/Rohen</option>
            <option value="edirne">Edirne</option>
            <option value="ron">Riders of the Night</option>
            <option value="karesi">Karesi</option>
            <option value="altay">Altay Ailesi</option>
            <option value="seljuk">Selçuklu Ailesi</option>
            <option value="sl">Steel Legion</option>
            <option value="bursa">Bursa</option>
            <option value="saruhan">Saruhanlı Ailesi</option>
            <option value="albania">Albania</option>
            <option value="golemi">Golemi</option>
            <option value="greece">Greece</option>
            <option value="wallachia">Wallachia</option>
            <option value="serbia">Serbia</option>
            <option value="bosna">Bosna</option>
            <option value="venetia">Venetia</option>
            <option value="poland-welikoplskie">Poland-Welikoplskie</option>
            <option value="poland-mazowieckie">Poland-Mazowieckie</option>
            <option value="poland-krakowskie">Poland-Krakowskie</option>
            <option value="cehz">Cehz</option>
            <option value="wien">Wien</option>
            <option value="estergom">Estergom</option>
            <option value="oriente">Oriente</option>
            <option value="aegis">Aegis</option>
            <option value="sicilies">Sicilies</option>
          </select>
        </div>
        <div className={classes.group}>
          <label>Status*:</label>
          <select
            defaultValue={status}
            onChange={(e) => setStatus(e.target.value)}
            disabled={disabled}
          >
            <option value={"friend"}>Friend</option>
            <option value="neutral">Neutral</option>
            <option value="enemy">Enemy</option>
            <option value="scout">Scout</option>
          </select>
        </div>
        {army && (
          <div className={classes.group}>
            <label>Behind Walls:</label>
            <select
              defaultValue={walls}
              onChange={(e) => setWalls(e.target.value)}
              disabled={disabled}
            >
              <option value={true}>Yes</option>
              <option value={false}>No</option>
            </select>
          </div>
        )}
        {army && (
          <div className={classes.group}>
            <label>Recruitment:</label>
            <select
              defaultValue={recruitment}
              onChange={(e) => setRecruitment(e.target.value)}
              disabled={disabled}
            >
              <option value={true}>Yes</option>
              <option value={false}>No</option>
            </select>
          </div>
        )}
        <div className={classes.group}>
          <label>Node*:</label>
          <input
            placeholder="Node"
            defaultValue={node}
            onChange={(e) => setNode(parseInt(e.target.value))}
            onWheel={(e) => e.target.blur()}
            disabled={disabled}
          />
        </div>
        <div className={classes.group}>
          <label>Unsure*:</label>
          <select
            defaultValue={unsure}
            onChange={(e) => setUnsure(e.target.value)}
            disabled={disabled}
          >
            <option value={false}>No</option>
            <option value={true}>Yes</option>
          </select>
        </div>
        <div className={classes.group}>
          <label>Notes:</label>
          <textarea
            type="textarea"
            defaultValue={comment}
            onChange={(e) => setComment(e.target.value)}
            disabled={disabled}
          />
        </div>
        {(!disabled && (userFaction === "admin" ||
          (userFaction === "poland" && (isPolandArmy || !army)))) && (
            <input type="submit" value="Submit" className={classes.btn} />
          )}
      </form>
      {!disabled && army &&
        (userFaction === "admin" ||
          (userFaction === "poland" && isPolandArmy)) && (
          <button
            onClick={() => {
              handleDelete();
            }}
            className={`${classes.btn} ${classes.delete}`}
          >
            Delete
          </button>
        )}
    </div>
  );
};

export default ArmyForm;
