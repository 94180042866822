import { useEffect, useState } from "react";
import {
  MapContainer,
  TileLayer,
  useMapEvents,
  AttributionControl,
} from "react-leaflet";
import { latLng, latLngBounds, GridLayer } from "leaflet";
import { nodes } from "../../Data/nodes";
import Control from "react-leaflet-custom-control";
import NodeBox from "./NodeBox";
import ArmiesBox from "./ArmiesBox";
import HistoryBox from "./HistoryBox";
import axios from "axios";
import NodeMarker from "./Markers/NodeMarker";
import ArmyMarker from "./Markers/ArmyMarker";
import { icons } from "../../Assets/Icons/Icons";
import classes from "./RKMap.module.scss";

const ZoomListener = ({ setZoomLevel }) => {
  const mapEvents = useMapEvents({
    zoomend: () => {
      setZoomLevel(mapEvents.getZoom());
    },
  });
  return null;
};

const RKMap = () => {
  const [center, setCenter] = useState([18, -22]);
  const [zoomLevel, setZoomLevel] = useState(6);
  const [selectedNode, setSelectedNode] = useState();
  const [showArmies, setShowArmies] = useState(false);
  const [armies, setArmies] = useState([]);
  const [filteredArmies, setFilteredArmies] = useState([]);
  const [showArmiesInfo, setShowArmiesInfo] = useState(true);
  const [showSoldiers, setShowSoldiers] = useState(true);
  const [showFriends, setShowFriends] = useState(true);
  const [fontSize, setFontSize] = useState(false);
  const [showHistory, setShowHistory] = useState(false);
  const [history, setHistory] = useState();
  const [selectedDate, setSelectedDate] = useState();
  const [selectedArmy, setSelectedArmy] = useState(null);

  useEffect(() => {
    const token = window.localStorage.getItem("loggedBLUser");
    // Fetch armies
    axios.get("https://battle-legion-backend.onrender.com/api/armies", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => setArmies(res.data.armies))
      .catch((e) => {
        if (e.response.status === 401) {
          // Token has expired
          window.localStorage.removeItem("loggedBLUser");
          window.location.reload();
        }
      });

    // Fetch history
    axios.get("https://battle-legion-backend.onrender.com/api/history", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => setHistory(res.data.history));
  }, []);

  useEffect(() => {
    setFilteredArmies(armies);
  }, [armies])

  useEffect(() => {
    if (selectedDate) {
      const selectedHistory = history.filter((h) => h.date === selectedDate);
      setArmies(selectedHistory[selectedHistory.length - 1].armies);
    }
  }, [selectedDate])

  const bounds = latLngBounds(latLng(-40, -180), latLng(90, 30));

  if (window?.chrome) {
    const originalInitTile = GridLayer.prototype._initTile;

    // Remove white lines grid
    GridLayer.include({
      _initTile: function (tile) {
        originalInitTile.call(this, tile);

        const tileSize = this.getTileSize();

        tile.style.width = `${tileSize.x + 1}px`;
        tile.style.height = `${tileSize.y + 1}px`;
      },
    });
  }

  const closeArmiesBox = () => {
    setShowArmies(false);
  };

  const closeNodeBox = () => {
    setSelectedNode(null);
  };

  const closeHistoryBox = () => {
    window.location.reload();
  };

  const toggleSoldiers = () => {
    setShowSoldiers(!showSoldiers);
  };

  const toggleFriends = () => {
    setShowFriends(!showFriends);
  };

  const toggleArmiesInfo = () => {
    setShowArmiesInfo(!showArmiesInfo);
  };

  const toggleFontSize = () => {
    setFontSize(!fontSize);
  };

  return (
    <MapContainer
      key={JSON.stringify(center)}
      center={center}
      zoom={zoomLevel}
      scrollWheelZoom={true}
      maxBoundsViscosity={1.0}
      maxBounds={bounds}
      attributionControl={false}
    >
      <TileLayer
        url={"../map/{z}/{x}/{y}.png"}
        minZoom={5}
        maxNativeZoom={6}
        maxZoom={7}
        noWrap={true}
      />
      {nodes.map((node, index) => (
        <NodeMarker
          key={`node-${node.id}`}
          position={node.position}
          zoomLevel={zoomLevel}
          armies={filteredArmies}
          node={node}
          showArmiesInfo={showArmiesInfo}
          showSoldiers={showSoldiers}
          showFriends={showFriends}
          fontSize={fontSize}
          selectedArmy={selectedArmy}
          onArmySelect={(army) => setSelectedArmy(army)}
          eventHandlers={{
            click: (e) => {
              setSelectedNode(node);
              setShowArmies(false);
            },
          }}
        />
      ))}
      {filteredArmies.map((army) => {
        const node = nodes.find((n) => n.id === army.node);
        if (!node) {
          return null;
        }
        const armiesOnNode = armies.filter((army) => army.node === node.id);
        return (
          <ArmyMarker
            key={`army-${army.id}`}
            position={node.position}
            zoomLevel={zoomLevel}
            armies={armiesOnNode}
            nodes={nodes}
            selectedArmy={selectedArmy}
            showTooltip={armiesOnNode[armiesOnNode.length - 1]?.id === army.id}
            eventHandlers={{
              click: (e) => {
                setSelectedNode(node);
                setShowArmies(false);
              },
            }}
          />
        );
      })}
      <Control position="topleft">
        <ul className={classes.mapMenu}>
          <h3 title="Armies">
            <icons.GiKnightBanner size={40} onClick={() => {
              setSelectedNode(null);
              setShowArmies(true);
            }} />
          </h3>
          <h3 title="History">
            <icons.MdHistory onClick={() => {
              setShowHistory(true);
            }} />
          </h3>
          <h3 title="Show/Hide Armies' Info">
            {showArmiesInfo ? (
              <icons.AiFillEyeInvisible onClick={toggleArmiesInfo} />
            ) : (
              <icons.AiFillEye onClick={toggleArmiesInfo} />
            )}
          </h3>
          <h3 title="Show/Hide Armies' Soldiers">
            <icons.BsPeopleFill onClick={toggleSoldiers} />
          </h3>
          <h3 title="Show/Hide Friends' Soldiers">
            <icons.GiHeartTower onClick={toggleFriends} />
          </h3>
          <h3 title="Toggle Font Size">
            <icons.TiSortAlphabeticallyOutline onClick={toggleFontSize} />
          </h3>
        </ul>
      </Control>
      <Control position="topright">
        <div className={classes.control}>
          {selectedNode && (
            <NodeBox 
              node={selectedNode} 
              armies={filteredArmies} 
              onClose={closeNodeBox}
              disabled={selectedDate ? true : false}
            />
          )}
          {showArmies && (
            <ArmiesBox
              armies={armies}
              filteredArmies={filteredArmies}
              setFilteredArmies={setFilteredArmies}
              nodes={nodes}
              onClose={closeArmiesBox}
              setCenter={setCenter}
              disabled={selectedDate ? true : false}
            />
          )}
        </div>
      </Control>
      <Control position="bottomleft">
        {showHistory && (
          <HistoryBox
            data={history}
            onClose={closeHistoryBox}
            selectedDate={selectedDate}
            setSelectedDate={setSelectedDate}
          />
        )}
      </Control>
      <ZoomListener setZoomLevel={setZoomLevel} />
      <AttributionControl prefix="Battle Legion Tacking System" />
    </MapContainer>
  );
};

export default RKMap;
