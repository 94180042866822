import {
  AiFillHome,
  AiFillEye,
  AiFillEyeInvisible,
  AiOutlineSound,
} from "react-icons/ai";
import { BsInfoCircleFill, BsPeopleFill, BsIncognito } from "react-icons/bs";
import { BiBook } from "react-icons/bi";
import { MdAccountTree, MdPersonAdd, MdHistory, MdAdminPanelSettings } from "react-icons/md";
import { FiFilm } from "react-icons/fi";
import { TbBuildingCastle, TbMapPinOff, TbMapOff } from "react-icons/tb";
import { GiKnightBanner, GiWingedShield, GiHeartTower } from "react-icons/gi";
import { TiSortAlphabeticallyOutline } from "react-icons/ti";
import { PiTentDuotone } from "react-icons/pi";

export const icons = {
  AiFillHome,
  AiFillEye,
  AiFillEyeInvisible,
  AiOutlineSound,
  BsInfoCircleFill,
  BiBook,
  BsPeopleFill,
  BsIncognito,
  FiFilm,
  GiKnightBanner,
  GiWingedShield,
  GiHeartTower,
  MdAccountTree,
  MdPersonAdd,
  MdHistory,
  MdAdminPanelSettings,
  PiTentDuotone,
  TbBuildingCastle,
  TbMapPinOff,
  TbMapOff,
  TiSortAlphabeticallyOutline,
};
